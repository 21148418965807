import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { User } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';

import { useUserState } from './user';
import { useGroups } from './groupsModel';
import Loading from './Loading';
import { defaultMaxGroups } from './common/firestoreTypes';
import { InviteRequest, InviteResponse } from './common/functionsTypes';
import { showError } from './modal';

// Returns true iff the email is valid. Permissive.
function validEmail(email: string) {
  // eslint-disable-next-line no-control-regex
  return (/^[^\s@]+@[^\s@]+\.[^\s@]+$/u).test(email);
}

type NewGroupProps = {
  user: User,
}

export default function NewGroup({
  user,
}: NewGroupProps) {
  const functions = useFunctions();
  const userStateModel = useUserState();
  const { userState } = userStateModel;
  const groupsSnap = useGroups(user.uid);
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [inviteeName, setInviteeName] = useState('');
  const [defaultLocation, setDefaultLocation] = useState('');
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [groupId, setGroupId] = useState('');
  const valid = Boolean(validEmail(inviteeEmail) && inviteeName);

  if (userStateModel.loading || !groupsSnap) {
    return (
      <Loading />
    );
  }

  const maxGroups = Math.max(userState.maxGroups || 0, defaultMaxGroups);
  const groupsRemaining = maxGroups - groupsSnap.size;
  const disabled = groupsRemaining <= 0;

  const submit = async () => {
    const invite = httpsCallable<InviteRequest, InviteResponse>(functions, 'invite');
    try {
      setSending(true);
      const response = await invite({ inviteeName, inviteeEmail, defaultLocation });
      setGroupId(response.data.groupId);
      setSent(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error sending invitation:', error);
      showError(error.message);
    } finally {
      setSending(false);
    }
  };

  if (sent) {
    return (
      <>
        <h3>Invitation sent</h3>
        <Link to={`/group/${groupId}`}>
          See group details
        </Link>
        <br />
        <button onClick={() => {
          setSent(false);
          setInviteeEmail('');
          setInviteeName('');
          setGroupId('');
        }}>
          Send another invitation
        </button>
      </>
    );
  }

  if (sending) {
    return (
      <h3>Sending...</h3>
    );
  }

  return (
    <div>
      <h2>Creating New Connection</h2>
      <p>
        Invite a friend to keep in touch:
      </p>
      Friend&apos;s name:&nbsp;
      <input className="w-72"
        value={inviteeName}
        placeholder="Name"
        onChange={(event) => { setInviteeName(event.target.value); }}
        disabled={disabled}
      />
      &ensp;(required)
      <br />
      Email address:&nbsp;
      <input className="w-72"
        value={inviteeEmail}
        placeholder="Email"
        onChange={(event) => { setInviteeEmail(event.target.value.trim()); }}
        disabled={disabled}
      />
      &ensp;(required)
      <br />
      Default location:&nbsp;
      <input className="w-72"
        value={defaultLocation}
        placeholder="or video chat URL"
        onChange={(event) => { setDefaultLocation(event.target.value); }}
        disabled={disabled}
      />
      &ensp;(leave blank to create a new <a href="https://meet.jit.si" target="_blank" rel="noreferrer">Jitsi Meet</a> chat room)
      <br />
      <button onClick={submit} disabled={disabled || !valid}>
        Submit
      </button>
      <br />
      <br />
      <hr />
      { disabled ? (
        <p>
          <b>
            You&apos;ve hit the current maximum number of connections ({ maxGroups }).
          </b>
          &ensp;We will probably increase the limit eventually.
        </p>
      ) : (
        <p>
          You can create <b>{ groupsRemaining }</b> more connection{ groupsRemaining !== 1 && 's' }.
        </p>
      )}
    </div>
  );
}
